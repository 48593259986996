























import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class Avatar extends Vue {
  @Prop({ default: true }) requireWallet!: boolean
  @Prop({ default: true }) requireLogin!: boolean
  @Prop({}) profile!: any
  @Prop({ default: '60' }) size!: any
  @Prop({ default: true }) shouldOpenProfile!: boolean

  getProfileId(profile) {
    if (profile['google_user']) {
      if (typeof profile['google_user'] === 'object') {
        return profile['google_user'].id
      } else {
        return profile['google_user']
      }
    } else {
      if (typeof profile['users_permissions_user'] === 'object') {
        return profile['users_permissions_user'].id
      } else {
        return profile['users_permissions_user']
      }
    }
  }
  get defaultAvatar() {
    const defaultAvatarIndex = this.profile?.defaultAvatarIndex
    return defaultAvatarIndex != undefined
      ? `${process.env.VUE_APP_API_ENDPOINT}/uploads/profile-avatars/profile-avatar-${defaultAvatarIndex}.png`
      : undefined
  }
}
